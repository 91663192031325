export default function (pageName, pageParams, pageClasses, backUrl, contractText = '', introText = '', outtroText = '') {
	this.wrap('page/wrapper', pageName, pageParams, pageClasses);
	return `

		${this.cond(introText.length, () => `
			<div ${this.html.classAttr('block__text', 'block__text--contractIntro', 'ugc')}>
				${introText}
			</div>
		`)}

		<div ${this.html.classAttr('block__pageTitle', 'block__pageTitle--contract')}>
			<h2>${this.text.get('application/contract')}</h2>
			<div ${this.html.classAttr('pageActions')}>
				<button ${this.html.classAttr('iconButton', 'pageActions__iconBtn')} ${this.html.dataAttr({component: 'PrintBtn'})} type="button">
					${this.html.svgIcon('print')}
				</button>
			</div>
		</div>

		<div ${this.html.classAttr('block__text', 'block__text--contract', 'ugc')}>
			${contractText}
		</div>

		${this.cond(outtroText.length, () => `
			<div ${this.html.classAttr('block__text', 'block__text--contractOuttro', 'ugc')}>
				${outtroText}
			</div>
		`)}

		<div ${this.html.classAttr('pageActions')}>
			<button ${this.html.classAttr('iconButton', 'pageActions__iconBtn')} ${this.html.dataAttr({component: 'PrintBtn'})} type="button">
				${this.html.svgIcon('print')}
			</button>
		</div>

	`;
}
