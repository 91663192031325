export default function (field) {
	if (field.type === 'tags') {
		if (Array.isArray(field.value)) {
			return this.escape(field.value.join(', '));
		}
	} else if(field.type === 'sum') {
		return this.render('form/field/sumTable', field, true);
	} else if(field.type === 'file') {
		if (field.value && 'size' in field.value && field.value.size) {
			let originalName = field.value.originalName;
			if (originalName.length > 40) {
				originalName = originalName.substr(0, 37) + '...';
			}
			return `
				${this.escape(originalName)}<br />
				${this.escape(field.value.ext.toUpperCase())} ${this.escape(this.string.fileSize(field.value.size))}
			`;
		}
		return '';
	}
	return this.string.nl2br(this.escape(field.value));
}
